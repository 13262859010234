<template>
	<section>
		<div class="bg-cover-text overflow-hide">
			<div class="container-lg cng">
				<div class="row no-gutters">
					<div class="col-lg-12">
						<div class="cover-slide">
							<div class="slide-item slide-individual">
								<div class="slidetext slidetext-1">
									<div class="line1" v-html="$t('TELEMEDICINE')"></div>
									<div class="line3" v-html="$t('Consulting with expertise doctors anywhere anytime')"></div>
									<div class="line4">
										<a :href="urlWebTelemed" target="_blank" v-html="$t('Consulting through Website')"></a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="container pb-0 pt-md-4 pt-4">
				<div class="row">
					<div class="col-12 text-center">
						<h1 class="blue text-bold" v-html="$t('FOR INDIVIDUAL')"></h1>
					</div>
				</div>
				<div class="row">
					<div class="col-md-10 offset-md-1 text-center my-5">
						<h2 class="blue"><b v-html="$t('Our Aim')"></b></h2>
						<p v-html="$t('We aim to enhance the quality of healthcare services nationally and internationally. Providing the standard of healthcare treatments to everyone equally. Saving people\'s lives with less time-consuming and limited costs')"></p>
					</div>
					<u-animate-container class="col-md-10 offset-md-1 text-center mb-0 mb-md-5">
						<u-animate class="aim-box" name="fadeInUp">
							<div class="icon"><img src="@/assets/images/icon-aim2.svg" /></div>
							<div class="title" v-html="$t('Save Time')"></div>
						</u-animate>
						<u-animate class="aim-box" name="fadeInUp">
							<div class="icon"><img src="@/assets/images/icon-aim3.svg" /></div>
							<div class="title" v-html="$t('Save Cost')"></div>
						</u-animate>
						<u-animate class="aim-box" name="fadeInUp">
							<div class="icon"><img src="@/assets/images/icon-aim1.svg" /></div>
							<div class="title" v-html="$t('Save Life')"></div>
						</u-animate>
					</u-animate-container>
				</div>
			</div>

			<div class="container-lg py-5 bg-adv">
				<div class="container">
					<div class="row">
						<div class="col-md-6 offset-md-6 text-center mt-5 pt-5">
							<h3 class="blue uppercase"><b v-html="$t('Who will get advantages from our services')"></b></h3>
							<div class="row adv">
								<div class="col-md-6 px-3" v-html="$t('People who have non emergency symptoms and don’t like to go to the hospital')"></div>
								<div class="col-md-6 px-3" v-html="$t('Medical tourists who need to know treatment plan & budget including arrange the appointment and medical trip')"></div>
								<div class="col-md-6 px-3" v-html="$t('Patients who doubt their current treatment')"></div>
								<div class="col-md-6 px-3" v-html="$t('Patients who need to compare prices from praivate hospitals')"></div>
							</div>
							<div>
								<a class="btn-demo" :href="urlWebTelemed" target="_blank" v-html="$t('Consult a doctor now')"></a>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="container-fluid bg-gray py-5">
				<div class="container-lg">
					<div class="container">
						<div class="row">
							<div class="col-md-10 offset-md-1 text-center my-4">
								<a id="how"></a>
								<h2 class="blue"><b v-html="$t('How it works')"></b></h2>
								<p v-html="$t('How it works Detail')"></p>
							</div>
						</div>
					</div>

					<div class="container-md" style="display: none;">
						<div class="row">
							<div class="col-12 text-center my-4">
								<a id="health"></a>
								<h3 class="blue strike-left"><b v-html="$t('Register Process')"></b></h3>
							</div>
						</div>
					</div>
					<div class="container-md" style="display: none;">
						<div class="row">
							<VueSlickCarousel :speed="1500" :dots="true" :pauseOnHover="false" :slidesToShow="2" :slidesToScroll="2" :responsive="[{ breakpoint: 767, settings: { slidesToShow: 1, slidesToScroll: 1 }}]" class="col-12 product-slide text-center my-4">
								<div class="product-slide-itemc mobile">
									<div class="product-slide-item">
										<div class="image"><img src="@/assets/images/how1.png" class="ir" /></div>
										<div class="content row">
											<div class="col-2 number">1</div>
											<div class="col-10 text">
												<p><b v-html="$t('Downlond Doctor A to Z Application')"></b></p>
											</div>
										</div>
									</div>
								</div>
								<div class="product-slide-itemc mobile">
									<div class="product-slide-item">
										<div class="image"><img src="@/assets/images/how2.png" class="ir" /></div>
										<div class="content row">
											<div class="col-2 number">2</div>
											<div class="col-10 text">
												<p><b v-html="$t('Create and account')"></b></p>
											</div>
										</div>
									</div>
								</div>
								<div class="product-slide-itemc mobile">
									<div class="product-slide-item">
										<div class="image"><img src="@/assets/images/how3.png" class="ir" /></div>
										<div class="content row">
											<div class="col-2 number">3</div>
											<div class="col-10 text">
												<p><b v-html="$t('Confirm with OTP code')"></b></p>
											</div>
										</div>
									</div>
								</div>
								<div class="product-slide-itemc mobile">
									<div class="product-slide-item">
										<div class="image"><img src="@/assets/images/how4.png" class="ir" /></div>
										<div class="content row">
											<div class="col-2 number">4</div>
											<div class="col-10 text">
												<p><b v-html="$t('Fill in your informations')"></b></p>
											</div>
										</div>
									</div>
								</div>
								<div class="product-slide-itemc mobile">
									<div class="product-slide-item">
										<div class="image"><img src="@/assets/images/how5.png" class="ir" /></div>
										<div class="content row">
											<div class="col-2 number">5</div>
											<div class="col-10 text">
												<p><b v-html="$t('Accept terms & conditions')"></b></p>
											</div>
										</div>
									</div>
								</div>
								<div class="product-slide-itemc mobile">
									<div class="product-slide-item">
										<div class="image"><img src="@/assets/images/how6.png" class="ir" /></div>
										<div class="content row">
											<div class="col-2 number">6</div>
											<div class="col-10 text">
												<p><b v-html="$t('Log in to Application')"></b></p>
											</div>
										</div>
									</div>
								</div>
							</VueSlickCarousel>
							<div class="col-12 text-center my-4 mt-5">
							</div>
						</div>
					</div>

					<div class="container-md">
						<div class="row">
							<div class="col-12 text-center my-4">
								<a id="health"></a>
								<h3 class="blue strike-right"><b v-html="$t('5 Simple Steps to Consult with Doctor')"></b></h3>
							</div>
						</div>
					</div>
					<div class="container-md">
						<div class="row">
							<VueSlickCarousel :speed="1500" :dots="true" :pauseOnHover="false" :slidesToShow="2" :slidesToScroll="2" :responsive="[{ breakpoint: 767, settings: { slidesToShow: 1, slidesToScroll: 1 }}]" class="col-12 product-slide text-center my-4">
								<div class="product-slide-itemc mobile">
									<div class="product-slide-item">
										<div class="image"><img src="@/assets/images/new-consult1.png" class="ir" /></div>
									</div>
								</div>
								<div class="product-slide-itemc mobile">
									<div class="product-slide-item">
										<div class="image"><img src="@/assets/images/new-consult2.png" class="ir" /></div>
									</div>
								</div>
								<div class="product-slide-itemc mobile">
									<div class="product-slide-item">
										<div class="image"><img src="@/assets/images/new-consult3.png" class="ir" /></div>
									</div>
								</div>
								<div class="product-slide-itemc mobile">
									<div class="product-slide-item">
										<div class="image"><img src="@/assets/images/new-consult4.png" class="ir" /></div>
									</div>
								</div>
								<div class="product-slide-itemc mobile">
									<div class="product-slide-item">
										<div class="image"><img src="@/assets/images/new-consult5.png" class="ir" /></div>
									</div>
								</div>
								<div class="product-slide-itemc mobile">
									<div class="product-slide-item">
										<div class="image"><img src="@/assets/images/new-consult6.png" class="ir" /></div>
									</div>
								</div>
							</VueSlickCarousel>
							<div class="col-12 text-center my-4 mt-5">
							</div>
						</div>
					</div>
				</div>
			</div>

			<a id="testimonial"></a>
			<div class="container py-0 pb-md-5">
				<div class="row">
					<div class="col-12 py-5 text-center">
						<h2><b v-html="$t('Testimonials')"></b></h2>
					</div>
					<div class="col-12 pb-5 text-center">
						<VueSlickCarousel :autoplay="true" :speed="1500" :pauseOnHover="false" class="row testimonial-slide">
							<div class="col-12">
								<div class="row">
									<div class="col-md-3 text-center px-4">
										<div class="image-container"><i class="quote"></i>
											<div class="image"><img src="@/assets/images/media/testimonial1.jpg" class="ir" /></div>
										</div>
									</div>
									<div class="col-md-9 px-5">
										<div class="content" v-html="$t('Doctor A to Z is engaged in not just offering technology, but helping us solve problems - and that’s what we need')"></div>
										<div class="by"><b>Doctor A to Z | </b><i class="star"></i><i class="star"></i><i class="star"></i><i class="star"></i><i class="star"></i></div>
									</div>
								</div>
							</div>
						</VueSlickCarousel>
					</div>
				</div>
			</div>

			<a id="qna"></a>
			<div class="container-fluid bg-white">
				<div class="container-md py-5 px-3 px-md-5">
					<div class="row">
						<div class="col-12 text-center">
							<h2><b v-html="$t('Browse Questions')"></b></h2>
						</div>
					</div>
					<div class="row">
						<div class="col-12">
							<div class="carousel qna">
								<div class="title" :class="{'active': this.qna1}" @click.prevent="toggleActive('qna1')" v-html="$t('pq1')"></div>
								<div class="content" :class="{'active': this.qna1}">
									<div class="content-item" v-html="$t('pa1')"></div>
								</div>
							</div>
							<div class="carousel qna">
								<div class="title" :class="{'active': this.qna2}" @click.prevent="toggleActive('qna2')" v-html="$t('pq2')"></div>
								<div class="content" :class="{'active': this.qna2}">
									<div class="content-item" v-html="$t('pa2')"></div>
								</div>
							</div>
							<div class="carousel qna">
								<div class="title" :class="{'active': this.qna3}" @click.prevent="toggleActive('qna3')" v-html="$t('pq3')"></div>
								<div class="content" :class="{'active': this.qna3}">
									<div class="content-item" v-html="$t('pa3')"></div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'

export default {
	components: {
		VueSlickCarousel
	},
	data: () => ({
		urlWebTelemed: process.env.VUE_APP_URL_WEB_TELEMED,
		qna1: false,
		qna2: false,
		qna3: false
	}),
	methods: {
		toggleActive(name) {
			this[name] = !this[name]
		}
	},
}
</script>